import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Disclosure } from '@headlessui/react';
import { Badge } from 'components';
import { Spinner } from 'components/Spinner';
import { QUESTION_DIFFICULTY } from 'constant';
import {FC, memo, useEffect, useState} from 'react';

type Props = {
  directoryId?: number
  initialData?: BrowseItem[]
  indent?: number
  onSelect: (item: BrowseItem) => void
  selected?: BrowseItem[]
  getList: (params: BrowseParams) => Promise<any>
}

const List: FC<Props> = (props) => {
  const {
    directoryId,
    initialData = [],
    getList
  } = props;

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<BrowseItem[]>(initialData);

  useEffect(() => {
    if (initialData.length) {
      setData(initialData);
    }
  }, [initialData]);

  useEffect(() => {
    if (directoryId) {
      setLoading(true);
      getList({
        directory_id: directoryId,
        per_page: 200
      })
      .then((response) => setData(response?.data || []))
      .catch((err) => console.log('Err', err))
      .finally(() => setLoading(false));
    }
  }, [directoryId]);

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <RenderItem data={data} {...props}/>
      )}
    </>
  );
};

const RenderItem: FC<Props & {data: BrowseItem[]}> = memo((props) => {
  const {
    data,
    indent = 1,
    onSelect,
    selected = [],
  } = props;

  return (
    data.length ? (
      <>
        {data.map((item) => {
          const isDirectory = item.type === 'directory';
          const isSelected = Boolean(selected.find((val) => val.id === item.id));
          let paddingLeft = 32 * (indent - 1);
          paddingLeft = paddingLeft ? paddingLeft : 4;
    
          return (
            isDirectory ? (
              <Disclosure key={`directory-${item.id}`}>
                {({ open }) => {
                  const itemIcon = open ? icon({name: 'chevron-down'}) : icon({name: 'chevron-right'});

                  return (
                    <>
                      <div
                        className={`
                          flex 
                          w-full 
                          items-center 
                          gap-x-4 
                          pr-4 
                          py-2 
                          text-left 
                          font-medium
                          border-b
                        `}
                        style={{
                          paddingLeft,
                        }}
                      >
                        <FontAwesomeIcon icon={itemIcon} width={16} />
                        <div
                          className="flex items-center gap-x-3 w-full"
                        >
                          <FontAwesomeIcon icon={icon({name: 'folder'})} />
                          <span>{item.name}</span>
                        </div>
                      </div>
                    </>
                  )
                }}
              </Disclosure>
            ) : (
              <label
                key={`question-${item.id}`}
                className={`flex justify-between w-full gap-x-4 py-2 px-1 cursor-pointer hover:bg-yellow-100 border-b ${isSelected ? 'bg-yellow-100' : 'bg-white'}`}
              >
                <div className='flex w-full items-center gap-x-4'>
                  <input
                    type="checkbox"
                    id={`question-${item.id}`}
                    className="checkbox checked:bg-yellow-400 checked:border-primary text-gray-500"
                    onChange={() => onSelect(item)}
                    checked={isSelected}
                  />
                  <div className='flex w-full items-center gap-x-2'>
                    <FontAwesomeIcon icon={icon({name: 'file-lines'})} />
                    <p>{item.name}</p>
                  </div>
                </div>
                <div className='flex items-center gap-x-2'>
                  <Badge color='blue'>
                    {`${item.n_multiple_choice} PG`}
                  </Badge>
                  <Badge color='success'>
                    {`${item.n_essay} essay`}
                  </Badge>
                  <Badge color='gray'>
                    {`${QUESTION_DIFFICULTY[Number(item.difficulty)]}`}
                  </Badge>
                </div>
              </label>
            )
          )
        })}
      </>
    ) : (
      <p className="text-center" />
    )
  )
});

export default memo(List);
