import { useCallback, useState } from "react";
import useProgram from "services/useProgram";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Badge, Button, Spinner } from "components";
import DeletePrompt from "./fragment/Modal/DeletePrompt";
import Categories from "./fragment/Modal/Categories";
import { currencyConverter, getFormatDate } from "utilities";
import toast from "react-hot-toast";
import { PROGRAM_TYPE } from "constant";
import Filter from "./fragment/Filter";
import Footer from "./fragment/Footer";
import ButtonDropdown from "components/dropdowns/ButtonDropdown";
import { useNavigate } from "react-router-dom";

const defaultPagination: PaginationResponse = {
  current_page: 1,
  per_page: 10,
  last_page: 1,
  from: 1,
  to: 1,
  total: 1,
};

const Program = () => {
  const navigate = useNavigate();
  const { getList, deleteProgram, updateProgram, updateProgramPriority } =
    useProgram();

  const [showDelete, setShowDelete] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [detail, setDetail] = useState<ProgramItem | null>(null);
  const [data, setData] = useState<ProgramItem[]>([]);
  const [pagination, setPagination] = useState(defaultPagination);
  const [loading, setLoading] = useState(false);
  const [editingProgram, setEditingProgram] = useState<ProgramItem | null>(
    null
  );

  const getData = useCallback(async (params?: ProgramParams) => {
    setLoading(true);
    const response = await getList({
      ...params,
      grade_id: params?.grade_id || null,
    });
    const { data, ...restData } = response;

    setPagination(restData);
    setData(response?.data || []);
    setLoading(false);
    // eslint-disable-next-line
  }, []);

  const handleDelete = (item: ProgramItem) => {
    setDetail(item);
    setShowDelete(true);
  };

  const handlePublish = (item: ProgramItem, isPublished: boolean) => {
    toast.promise(updateProgram(item.id, isPublished), {
      loading: `${isPublished ? "Unpublish" : "Publish"} ${item.title}...`,
      success: () => {
        getData();
        return `Berhasil ${isPublished ? "Unpublish" : "Publish"} ${
          item.title
        }`;
      },
      error: `Gagal ${isPublished ? "Unpublish" : "Publish"} ${item.title}`,
    });
  };

  const confirmDelete = () => {
    toast.promise(deleteProgram(Number(detail?.id)), {
      loading: `Hapus ${detail?.title}...`,
      success: () => {
        setShowDelete(false);
        getData();
        return `${detail?.title} telah dihapus.`;
      },
      error: "Gagal hapus program",
    });
  };

  const navigateDetail = (id: number, type: ProgramItem["type"]) => {
    navigate(`detail/${id}`, {
      relative: "path",
      state: { type },
    });
  };

  const handleUpdatePriority = (item: ProgramItem, priority: number) => {
    toast.promise(updateProgramPriority(item.id, { priority }), {
      loading: `Update urutan ${item.title}...`,
      success: () => {
        getData();
        setEditingProgram(null);
        return `Berhasil update urutan ${item.title}`;
      },
      error: `Gagal update urutan ${item.title}`,
    });
  };

  const getTypeColor = (type: ProgramItem["type"]) => {
    switch (type) {
      case "learn":
        return "purple-light";
      case "tryout":
        return "warning-light";
      default:
        return "blue-light";
    }
  };

  return (
    <>
      <div className="flex items-center justify-between mb-5">
        <div>
          <p className="font-bold">{`Total ${pagination.total} program`}</p>
          <p className="text-xs text-gray-600">Seluruh program yang dibuat</p>
        </div>
        <Button.Create
          text="Tambah program"
          onButtonClick={() => setShowAdd(true)}
        />
      </div>
      <Filter onGetData={getData} />
      <div className="grid gap-y-5">
        {loading ? (
          <Spinner />
        ) : data.length ? (
          data.map((val, index) => {
            const isPublished = val.status.toLocaleLowerCase() === "published";

            return (
              <div
                key={`${val.id}-${index}`}
                className="bg-white flex flex-wrap gap-2 px-5 py-3 rounded-lg"
              >
                <img
                  className="h-24 w-24 object-fit rounded"
                  src={val.thumbnail_path ?? "/assets/noImage.png"}
                  alt={`thumbnail-${val.title}`}
                />
                <div className="flex-grow md:w-72 flex flex-col justify-between items-start">
                  <p className="font-bold text-ellipsis line-clamp-3">
                    {val.title}
                  </p>
                  <Badge color={getTypeColor(val.type)}>
                    {PROGRAM_TYPE[val.type].toUpperCase()}
                  </Badge>
                </div>
                <div className="md:w-32 flex flex-grow flex-col justify-between items-start">
                  <div>
                    <p className="text-xs text-gray-600">Strata</p>
                    <span className="text-sm font-medium text-ellipsis line-clamp-2">
                      {val?.strata || "-"}
                    </span>
                  </div>
                  <div>
                    <p className="text-xs">Periode</p>
                    <span className="text-sm">
                      {getFormatDate(val?.registration_period_start)}
                    </span>
                  </div>
                </div>
                <div className="md:w-32 flex-grow flex flex-col justify-between items-start">
                  <div>
                    <p className="text-xs">Harga program</p>
                    <span className="text-sm">
                      {currencyConverter(Number(val.price))}
                    </span>
                  </div>
                  <div>
                    <p className="text-xs">Diskon</p>
                    <span className="text-sm text-red-500">
                      {val.is_flat_disc
                        ? currencyConverter(Number(val.discount))
                        : `${val.discount}%`}
                    </span>
                  </div>
                </div>
                <div className="md:w-28 w-32 flex-col flex items-end justify-between">
                  {editingProgram?.id === val.id ? (
                    <>
                      <label className="font-bold">Urutan</label>
                      <input
                        type="number"
                        placeholder="Masukan urutan"
                        className="bg-white h-[2rem] w-[5rem] border-b-[1px] border-b-gray-500 focus:border-b-[1px] focus:border-b-[#FFF200] mb-2 text-right"
                        value={editingProgram.view_priority ?? 0}
                        onChange={(e) =>
                          setEditingProgram({
                            ...editingProgram,
                            view_priority: parseInt(e.target.value),
                          })
                        }
                      />
                      <Badge color="warning">
                        <button
                          className="flex items-center gap-x-1 font-medium text-sm"
                          onClick={() => {
                            handleUpdatePriority(
                              editingProgram,
                              editingProgram.view_priority ?? 0
                            );
                          }}
                        >
                          <FontAwesomeIcon icon={icon({ name: "save" })} />
                          <span>{"Simpan"}</span>
                        </button>
                      </Badge>
                    </>
                  ) : (
                    <>
                      <p className="font-bold text-ellipsis line-clamp-3">
                        Urutan: {val.view_priority ? val.view_priority : "-"}
                      </p>
                      <Badge color="warning">
                        <button
                          className="flex items-center gap-x-1 font-medium text-sm"
                          onClick={() => {
                            setEditingProgram(val);
                          }}
                        >
                          <FontAwesomeIcon icon={icon({ name: "edit" })} />
                          <span>{"Ganti"}</span>
                        </button>
                      </Badge>
                    </>
                  )}

                  <Badge color={isPublished ? "success" : "warning-light"}>
                    <p className="flex items-center gap-x-1 font-medium text-sm">
                      {isPublished ? (
                        <FontAwesomeIcon
                          icon={icon({ name: "check-circle" })}
                        />
                      ) : (
                        <FontAwesomeIcon icon={icon({ name: "pen" })} />
                      )}
                      <span>{val.status}</span>
                    </p>
                  </Badge>
                </div>
                <div className="flex-grow flex items-center justify-end">
                  <ButtonDropdown
                    menuItems={[
                      <button
                        className="w-full flex items-center justify-start gap-x-2 px-3.5 py-2.5 hover:bg-gray-50"
                        onClick={() => navigateDetail(val.id, val.type)}
                      >
                        <FontAwesomeIcon
                          icon={icon({ name: "arrow-up-right-from-square" })}
                        />
                        <p className="text-sm">Detail program</p>
                      </button>,
                      <button
                        className="w-full flex items-center justify-start gap-x-2 px-3.5 py-2.5 hover:bg-gray-50"
                        onClick={() => handlePublish(val, isPublished)}
                      >
                        {isPublished ? (
                          <>
                            <FontAwesomeIcon
                              icon={icon({ name: "rotate-left" })}
                            />
                            <p className="text-sm">Unpublish</p>
                          </>
                        ) : (
                          <>
                            <FontAwesomeIcon
                              icon={icon({
                                name: "paper-plane",
                                style: "regular",
                              })}
                            />
                            <p className="text-sm">Publish</p>
                          </>
                        )}
                      </button>,
                      <button
                        className="w-full flex items-center justify-start gap-x-2 px-3.5 py-2.5 hover:bg-gray-50"
                        onClick={() => handleDelete(val)}
                      >
                        <FontAwesomeIcon icon={icon({ name: "trash-alt" })} />
                        <p className="text-sm">Remove</p>
                      </button>,
                    ]}
                    widthItems="w-40"
                  >
                    <div className="text-end">
                      <FontAwesomeIcon
                        icon={icon({ name: "ellipsis-vertical" })}
                        className="cursor-pointer"
                      />
                    </div>
                  </ButtonDropdown>
                </div>
              </div>
            );
          })
        ) : (
          <p className="text-center italic">Tidak ada data</p>
        )}
        <Footer pagination={pagination} onGetData={getData} />
      </div>
      <DeletePrompt
        show={showDelete}
        onClose={() => setShowDelete(false)}
        onDelete={confirmDelete}
      />
      <Categories show={showAdd} onClose={() => setShowAdd(false)} />
    </>
  );
};

export default Program;
