// API
import { services } from "services";

// Components
import { Spinner } from "components";
import { FormSection } from "../components";
import Breadcrumbs from "components/Breadcrumbs";

// Form
import { FormProvider, useFieldArray, useForm } from "react-hook-form";

// Third-Party Libraries
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";

// Types
import { ErrorFetch, SuccessFetch } from "types";
import { FormSyllabus, Form as FormType } from "../types";

// Utilities
import { baseData } from "../utilities";
import { API_URL } from "constant";

// View Models
import { Single, useSingle } from "./viewModels";
import { useCallback, useEffect, useState } from "react";
import Meeting from "pages/Syllabus/fragment/Meeting";
import { ExamKind } from "../Tambah";
import Exam from "pages/Syllabus/fragment/Exam";

export type Form = FormType;

const defaultCrumbs: Crumb[] = [
  {
    label: "Silabus",
    id: 0,
  },
  {
    label: "Ubah Silabus",
    id: 1,
  },
];

export default function Ubah(): JSX.Element {
  // Hooks
  const param = useParams();
  const id = param.id ?? "";

  // Fetch data using custom hook
  const { data, isLoading, error } = useSingle(id);

  // Handle errors and perform redirection
  useEffect(() => {
    if (error) {
      if (error.response?.status === 404) {
        toast.error("Silabus tidak ditemukan, anda akan kembali ke daftar silabus...", {
          position: "top-center",
          duration: 2000,
        });
        setTimeout(() => {
          window.location.href = "/silabus";
        }, 3000);
      } else if (
        Number(error.response?.status) >= 500 ||
        error.code === "ERR_NETWORK"
      ) {
        toast.error("Terjadi Kesalahan, silahkan coba beberapa saat lagi.", {
          position: "top-center",
          duration: 2000,
        });
        setTimeout(() => {
          window.location.href = "/silabus";
        }, 3000);
      }
    }
  }, [error]);

  // Render loading spinner or the data section based on loading state
  if (isLoading) return <Spinner />;
  if (error) return <> </>; // Avoid rendering DataSection if there is an error

  return <DataSection data={data} id={id} />;
}
function DataSection(props: { data: Single; id: string }) {
  // Hooks
  const navigate = useNavigate();
  const [state, setState] = useState({
    isMeeting: false,
    packageIndex: 0,
    isEdit: false,
    isExam: false,
    packageExamKind: "uh",
  });

  // API
  const methods = useForm<FormSyllabus>({
    defaultValues: {
      grade_id: props.data.grade_id,
      subject_id: null,
      major_id: null,
      name: props.data.name,
      description: props.data.description,
      packages: props.data.packages,
      exams: props.data.exams,
    }, // Casting to unknown first, then to Resolver<FormSyllabus>
  });
  const { control, handleSubmit } = methods;
  const { insert, remove, fields } = useFieldArray({
    control,
    name: "packages",
  });

  const onSubmit = (values: FormSyllabus) => {
    const dataExams: FormSyllabus["exams"] = {
      uh: {
        directories: [],
      },
      pat: {
        directories: [],
      },
      pas: {
        directories: [],
      },
      pts1: {
        directories: [],
      },
      pts2: {
        directories: [],
      },
    };

    if (values.exams && values.exams.uh && values.exams.uh.directories) {
      dataExams.uh.directories = values.exams.uh.directories;
    }

    if (values.exams && values.exams.pat && values.exams.pat.directories) {
      dataExams.pat.directories = values.exams.pat.directories;
    }

    if (values.exams && values.exams.pas && values.exams.pas.directories) {
      dataExams.pas.directories = values.exams.pas.directories;
    }

    if (values.exams && values.exams.pts1 && values.exams.pts1.directories) {
      dataExams.pts1.directories = values.exams.pts1.directories;
    }

    if (values.exams && values.exams.pts2 && values.exams.pts2.directories) {
      dataExams.pts2.directories = values.exams.pts2.directories;
    }

    const payload: AddSyllabusParams = {
      description: values.description,
      grade_id: values.grade_id,
      major_id: values.major_id,
      name: values.name,
      subject_id: values.subject_id,
      packages: values.packages.map((packageItem) => ({
        content_type: packageItem.content_type,
        description: packageItem.description,
        title: packageItem.title,
        directories: packageItem.directories.map((dir) => ({
          id: dir.directoryId,
          module_ids: dir.modules.map((module) => module.module_id),
          questions: dir.questions.map((question) => ({
            n_essay: question.n_essay,
            n_multiple_choice: question.n_multiple_choice,
            question_package_id: question.question_package_id,
            type: String(question.type),
          })),
        })),
      })),
      exams: {
        uh: {
          directories: dataExams.uh?.directories.map((dir) => ({
            directoryId: dir.directoryId,
            questions: dir.questions.map((question) => ({
              n_essay: question.n_essay,
              n_multiple_choice: question.n_multiple_choice,
              question_package_id: question.id,
              type: "exam",
              test_count: "all",
            })),
          })),
        },
        pat: {
          directories: dataExams.pat?.directories.map((dir) => ({
            directoryId: dir.directoryId,
            questions: dir.questions.map((question) => ({
              n_essay: question.n_essay,
              n_multiple_choice: question.n_multiple_choice,
              question_package_id: question.id,
              type: "exam",
              test_count: "all",
            })),
          })),
        },
        pas: {
          directories: dataExams.pas?.directories.map((dir) => ({
            directoryId: dir.directoryId,
            questions: dir.questions.map((question) => ({
              n_essay: question.n_essay,
              n_multiple_choice: question.n_multiple_choice,
              question_package_id: question.id,
              type: "exam",
              test_count: "all",
            })),
          })),
        },
        pts1: {
          directories: dataExams.pts1?.directories.map((dir) => ({
            directoryId: dir.directoryId,
            questions: dir.questions.map((question) => ({
              n_essay: question.n_essay,
              n_multiple_choice: question.n_multiple_choice,
              question_package_id: question.id,
              type: "exam",
              test_count: "all",
            })),
          })),
        },
        pts2: {
          directories: dataExams.pts2?.directories.map((dir) => ({
            directoryId: dir.directoryId,
            questions: dir.questions.map((question) => ({
              n_essay: question.n_essay,
              n_multiple_choice: question.n_multiple_choice,
              question_package_id: question.id,
              type: "exam",
              test_count: "all",
            })),
          })),
        },
      },
    };

    toast.promise(services.put(`${API_URL.syllabus}/${props.id}`, payload), {
      loading: "Loading...",
      success: (res: SuccessFetch<{ message: string }>) => {
        navigate(baseData.url, { replace: true, state: { useCache: true } });
        return res.data.message;
      },
      error: (err: ErrorFetch) => err.response.data.message,
    });
  };

  const handleCrumb = (item: Crumb) => {
    if (item.id === 0) {
      navigate("/silabus");
    }
  };

  const handleMeeting = useCallback(
    (id?: number) => {
      if (id !== undefined || typeof id === "number") {
        if (!state.isEdit) {
          remove(id);
        }
        setState((prev) => ({ ...prev, isEdit: false }));
      } else {
        const packageLength = fields.length;

        insert(packageLength, {
          content_type: "mixed",
          description: "",
          directories: [],
          title: "",
        });
        setState((prev) => ({
          ...prev,
          isEdit: false,
          isMeeting: true,
          isExam: false,
          packageIndex: packageLength,
        }));
      }
    },
    [fields.length, insert, remove, state]
  );

  const editMeeting = useCallback((index: number) => {
    setState((prev) => ({
      ...prev,
      isEdit: true,
      packageIndex: index,
      isMeeting: true,
    }));
  }, []);
  const editExam = useCallback((index: ExamKind) => {
    setState((prev) => ({
      ...prev,
      isEdit: true,
      packageIndex: 0,
      isMeeting: false,
      isExam: true,
      packageExamKind: index,
    }));
  }, []);
  return (
    <>
      {!state.isMeeting && (
        <Breadcrumbs
          crumbs={defaultCrumbs}
          onClick={(item) => handleCrumb(item)}
        />
      )}
      <FormProvider {...methods}>
        {state.isMeeting ? (
          <Meeting
            onCancel={() => handleMeeting(state.packageIndex)}
            onSubmit={() => setState((prev) => ({ ...prev, isMeeting: false }))}
            index={state.packageIndex}
          />
        ) : state.isExam && state.packageExamKind !== "" ? (
          <Exam
            onCancel={() => handleMeeting(state.packageIndex)}
            onSubmit={() => setState((prev) => ({ ...prev, isExam: false }))}
            index={state.packageExamKind as ExamKind}
          /> // Handle Exam UI here
        ) : (
          <>
            <FormSection
              onSetMeeting={() => handleMeeting()}
              onEditMeeting={(idx) => editMeeting(idx)}
              onEditExam={editExam}
            />
            <section className="flex py-5 gap-x-3 justify-end">
              <button
                onClick={() => navigate("/silabus")}
                className="px-4 py-[0.625rem] rounded-xl border border-primary bg-white hover:bg-primary"
              >
                Batal
              </button>
              <button
                onClick={handleSubmit(
                  (data) => {
                    onSubmit(data);
                  },
                  (err) => console.log(err)
                )}
                className="px-4 py-[0.625rem] rounded-xl bg-primary"
              >
                <FontAwesomeIcon
                  icon={icon({ name: "floppy-disk", style: "regular" })}
                  className="mr-[0.625rem]"
                />
                Simpan
              </button>
            </section>
          </>
        )}
      </FormProvider>
    </>
  );
}
